'use client';

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { node, object, string } from 'prop-types';

import { toRootCssVariables } from '@personly/libs-css';

const setCssRootVariables = (cssVariables) => {
  const rootCssVariables = toRootCssVariables(cssVariables);

  const root = document.querySelector(':root').style;

  for (const { key, value } of rootCssVariables) {
    root.setProperty(`--${key}`, value);
  }
};

const SchemesContext = createContext();

const SchemesProvider = ({ children, cookie, host, schemes, tld }) => {
  const [scheme, setScheme] = useState(schemes.scheme);
  const [cookieScheme, setCookieScheme] = useState(null);

  const schemeValid = useCallback(
    (scheme) => schemes.schemes.map(({ id }) => id).includes(scheme),
    [schemes.schemes]
  );

  useEffect(() => {
    (async () => {
      const cookieScheme = await cookie.get('schemeHandle');
      if (cookieScheme && schemeValid(cookieScheme)) {
        setCookieScheme(cookieScheme);
      }
    })();
  }, [schemeValid, cookie]);

  const updateScheme = useCallback(
    (scheme) => {
      if (scheme === 'SYSTEM') {
        if (window.matchMedia) {
          const browserSchemes = schemes.schemes
            .filter(({ flags }) => flags.browser)
            .map(({ id }) => id);

          for (const browserScheme of browserSchemes) {
            const media = `(prefers-color-scheme: ${browserScheme.toLowerCase()})`;

            if (window.matchMedia(media).matches) {
              setCssRootVariables(schemes.cssVariables[browserScheme]);
              setScheme(browserScheme);
              return;
            }
          }
        }

        setCssRootVariables(schemes.cssVariables[schemes.scheme]);
        setScheme(schemes.scheme);
      } else {
        setCssRootVariables(schemes.cssVariables[scheme]);
        setScheme(scheme);
      }
    },
    [schemes.scheme, schemes.schemes, schemes.cssVariables]
  );

  const setSchemeHandle = useCallback(
    (scheme) => {
      (async () => {
        const differs = cookieScheme !== scheme;
        const valid = !!cookieScheme && schemeValid(cookieScheme);
        const canChange = !cookieScheme || valid;
        if (differs && canChange) {
          const domain = await tld.getDomain(host);

          await cookie.set('schemeHandle', scheme, { domain });
          setCookieScheme(scheme);

          updateScheme(scheme);
        }
      })();
    },
    [cookie, cookieScheme, schemeValid, updateScheme, host, tld]
  );

  useEffect(() => {
    if (cookieScheme && schemeValid(cookieScheme)) {
      updateScheme(cookieScheme);
    }
  }, [cookieScheme, schemeValid, updateScheme]);

  const cssVariables = useMemo(
    () =>
      schemes.cssVariables[scheme].reduce((acc, cur) => {
        if (cur.value.length > 1) return { ...acc, [cur.key]: cur.value };
        return { ...acc, [cur.key]: cur.value };
      }, {}),
    [schemes.cssVariables, scheme]
  );

  const displaySchemeHandle = useMemo(
    () => cookieScheme || scheme,
    [cookieScheme, scheme]
  );

  const value = useMemo(
    () => ({
      schemeHandle: scheme,
      setSchemeHandle,
      cssVariables,
      cookieScheme,
      displaySchemeHandle,
    }),
    [setSchemeHandle, scheme, cssVariables, cookieScheme, displaySchemeHandle]
  );

  return (
    <SchemesContext.Provider value={value}>{children}</SchemesContext.Provider>
  );
};

SchemesProvider.propTypes = {
  children: node.isRequired,
  cookie: object.isRequired,
  host: string.isRequired,
  schemes: object.isRequired,
  tld: object.isRequired,
};

function useSchemes() {
  const context = useContext(SchemesContext);
  if (context === undefined) throw new Error('useSchemes requires a context!');

  return context;
}

export { SchemesProvider, useSchemes };
