'use client';

import { useCallback, useMemo } from 'react';

let cookiesPromise = null;

const loadCookie = () => {
  if (cookiesPromise === null) {
    cookiesPromise = import('js-cookie').then(({ default: Cookie }) => Cookie);
  }

  return cookiesPromise;
};

const useCookie = () => {
  const getCookie = useCallback(async (key) => {
    const lib = await loadCookie();
    return lib.get(key);
  }, []);

  const setCookie = useCallback(async (key, value, opts) => {
    const lib = await loadCookie();
    return lib.set(key, value, opts);
  }, []);

  const cookies = useMemo(
    () => ({ get: getCookie, set: setCookie }),
    [getCookie, setCookie]
  );

  return cookies;
};

export default useCookie;
