'use client';

import { node } from 'prop-types';

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUSPENSION_STARTED': {
      return { ...state, [action.data]: 'STARTED' };
    }

    case 'SUSPENSION_FINSHED': {
      return { ...state, [action.data]: 'FINISHED' };
    }
  }
};

const SuspensionsContext = createContext();

const SuspensionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const createSuspension = useCallback((handle) => {
    dispatch({ type: 'SUSPENSION_STARTED', data: handle });
  }, []);

  const removeSuspension = useCallback((handle) => {
    dispatch({ type: 'SUSPENSION_FINSHED', data: handle });
  }, []);

  const blocked = useMemo(
    () =>
      Object.values(state).length > 0 &&
      Object.values(state).every((state) => state === 'STARTED'),
    [state]
  );

  const suspended = useCallback((handle) => state[handle] || null, [state]);

  const suspensions = useMemo(
    () => ({ createSuspension, removeSuspension }),
    [createSuspension, removeSuspension]
  );

  const value = { blocked, suspended, suspensions };

  return (
    <SuspensionsContext.Provider value={value}>
      {children}
    </SuspensionsContext.Provider>
  );
};

SuspensionsProvider.propTypes = {
  children: node.isRequired,
};

function useSuspensions() {
  const context = useContext(SuspensionsContext);
  if (context === undefined)
    throw new Error('useSuspensions requires a context!');

  return context;
}

export { SuspensionsProvider, useSuspensions };
