'use client';

import { createContext, useCallback, useContext, useMemo } from 'react';
import { node, string } from 'prop-types';

import { useCookie } from '@personly/libs-hooks';

import { useNotifications } from '../';

const StreamsContext = createContext();

let streamsPromise = null;

const loadStreams = (role, url, onError) => {
  if (streamsPromise === null) {
    const options = {
      path: `/${role}`,
      transports: ['websocket', 'webtransport'],
    };

    streamsPromise = import('@personly/libs-streams').then(
      ({ default: Streams }) => new Streams(url, options, onError)
    );
  }

  return streamsPromise;
};

const StreamsProvider = ({ children, role, url }) => {
  const cookie = useCookie();
  const { createNotification } = useNotifications();

  const onError = useCallback(
    (err) => {
      const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
      createNotification('ERROR', code);
      console.error(err);

      if (code === 'UNAUTHENTICATED') {
        setTimeout(() => {
          window.location.href = '/auth/logout';
        }, 3000);
      }
    },
    [createNotification]
  );

  const subscribe = useCallback(
    async (channel, event, cb) => {
      const streams = await loadStreams(role, url, onError);
      const accessToken = await cookie.get('access_token');
      if (accessToken) streams.setAccessToken(accessToken);

      return streams.subscribe(channel, event, cb);
    },
    [role, url, onError, cookie]
  );

  const unsubscribe = useCallback(
    async (handle) => {
      const streams = await loadStreams(role, url, onError);

      return streams.unsubscribe(handle);
    },
    [role, url, onError]
  );

  const emit = useCallback(
    async (handle, event) => {
      const streams = await loadStreams(role, url, onError);

      streams.emit(handle, event);
    },
    [role, url, onError]
  );

  const value = useMemo(
    () => ({ subscribe, unsubscribe, emit }),
    [subscribe, unsubscribe, emit]
  );

  return (
    <StreamsContext.Provider value={value}>{children}</StreamsContext.Provider>
  );
};

StreamsProvider.propTypes = {
  children: node.isRequired,
  role: string.isRequired,
  url: string.isRequired,
};

function useStreams() {
  const context = useContext(StreamsContext);
  if (context === undefined) throw new Error('useStreams requires a context!');

  return context;
}

export { StreamsProvider, useStreams };
