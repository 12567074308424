'use client';

import { createContext, useCallback, useContext, useRef } from 'react';
import { func, node, string } from 'prop-types';

import { useCookie } from '@personly/libs-hooks';

import { useNotifications } from './notifications';
import { useSuspensions } from './suspensions';

const ResourcesContext = createContext();

let refreshPromise;

const refreshToken = () => {
  if (!refreshPromise) {
    // supress all errors here, as there is already retry logic in place
    // which will execute this function again
    refreshPromise = fetch('/auth/refresh').finally(() => {
      refreshPromise = null;
    });
  }

  return refreshPromise;
};

const ResourcesProvider = ({ children, loader, url }) => {
  const cookie = useCookie();
  const { suspensions } = useSuspensions();
  const { createNotification } = useNotifications();

  const resources = useRef(
    loader().then((resource) => {
      const options = { withCredentials: true };

      const clients = new resource.Clients(url, options);
      const messages = new resource.Messages();

      return { messages, clients };
    })
  );

  const onError = useCallback(
    (err) => {
      const code = err.message ? decodeURIComponent(err.message) : 'ERROR';
      createNotification('ERROR', code);
      console.error(err);

      if (code === 'UNAUTHENTICATED') {
        setTimeout(() => {
          window.location.href = '/auth/logout';
        }, 3000);
      }
    },
    [createNotification]
  );

  const value = useCallback(
    async (cb, suspensionHandles = []) => {
      const call = async (retries) => {
        try {
          suspensionHandles.forEach((handle) =>
            suspensions.createSuspension(handle)
          );

          const accessToken = await cookie.get('access_token');
          const metadata = {};
          if (accessToken) metadata.Authorization = `Bearer ${accessToken}`;

          const params = await Promise.resolve(resources.current);
          await cb({ ...params, metadata });
        } catch (err) {
          if (err.code === 16 && retries < 3) {
            await refreshToken();

            retries++;

            call(retries);
          } else {
            onError(err);
          }
        } finally {
          suspensionHandles.forEach((handle) =>
            suspensions.removeSuspension(handle)
          );
        }
      };

      call(0);
    },
    [suspensions, cookie, onError]
  );

  return (
    <ResourcesContext.Provider value={value}>
      {children}
    </ResourcesContext.Provider>
  );
};

ResourcesProvider.propTypes = {
  children: node.isRequired,
  loader: func.isRequired,
  url: string.isRequired,
};

function useResources() {
  const context = useContext(ResourcesContext);
  if (context === undefined)
    throw new Error('useResources requires a context!');

  return context;
}

export { ResourcesProvider, useResources };
