'use client';

import { createContext, useContext, useMemo, useRef } from 'react';
import { createIntl, createIntlCache } from '@formatjs/intl';
import { node, object } from 'prop-types';

const LocalizationContext = createContext();

const cache = createIntlCache();

const LocalizationProvider = ({ children, languages, messages }) => {
  const translations = useRef(
    createIntl({ locale: languages.locale, messages }, cache)
  );

  const value = useMemo(() => ({ translations: translations.current }), []);

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  );
};

LocalizationProvider.propTypes = {
  children: node.isRequired,
  languages: object.isRequired,
  messages: object.isRequired,
};

function useLocalization() {
  const context = useContext(LocalizationContext);
  if (context === undefined)
    throw new Error('useLocalization requires a context!');

  return context;
}

export { LocalizationProvider, useLocalization };
